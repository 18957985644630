import {useRef} from 'react';
import cn from 'classnames';

import {BLOG_PAGE_TYPE, type BlogCardProps} from '@/components/blog/types';
import {useTranslations} from '@/hooks/useTranslations';
import {filterArticleTag, topicEnPath} from '@/components/pages/blog/utils';
import Typography from '@/components/base/elements/Typography/Typography';
import {BlogAuthorName} from '@/components/pages/blog/BlogAuthor/BlogAuthorName';
import {getMetafieldAuthors} from '@/pages/shopify.com/($locale)/enterprise/blog/utils/utils';
import {twMerge} from '@/stylesheets/twMerge';

import ImageWrapper from './ImageWrapper';
import {
  blogCardImageStyles,
  eyebrowStyles,
  blogCardTitleStyles,
  blogCardTagStyles,
  blogCardDescriptionStyles,
  blogCardDateStyles,
  blogCardAuthorStyles,
  blogCardImageWrapperStyles,
} from './style';

export default function BlogCard({
  article,
  enBlogPath,
  type = 'noImage',
  className,
  preferredTag,
  blogType,
  translationNamespace,
  target = '_self',
  mode,
}: BlogCardProps) {
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const {localizePath} = useTranslations();
  const authorName = article.authorV2?.name;
  const isDisabledAuthor = article.authorV2?.isDisabledAuthor;
  const articleTopicStyles = twMerge(
    cn('no-underline', blogCardTagStyles({blogType, mode})),
  );
  const isHero = type === 'hero';

  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;
  const enterpriseAuthors = isEnterpriseBlog
    ? getMetafieldAuthors(article?.metafields, article.authorV2?.name)
    : [];

  if (!article) {
    return null;
  }

  let articleTag = filterArticleTag(article.tags);
  if (preferredTag) {
    articleTag =
      article.tags.find(
        (predicate) => predicate?.toLowerCase() === preferredTag.toLowerCase(),
      ) || articleTag;
  }

  const getTagLink = () => {
    if (preferredTag?.startsWith('/')) {
      return preferredTag;
    }

    return localizePath(
      topicEnPath(
        enBlogPath,
        isEnterpriseBlog && preferredTag ? preferredTag : articleTag,
      ),
    );
  };

  const authorData =
    isEnterpriseBlog && enterpriseAuthors
      ? enterpriseAuthors
      : [{name: authorName, isDisabledAuthor}];

  return (
    // NOTE: SEO uses "article--index" and "article--index--featured" classes for analysis
    <article
      className={cn(
        'article--index',
        {'article--index--featured': isHero},
        className,
      )}
    >
      {type !== 'noImage' && article.imageUrl && (
        <ImageWrapper
          href={linkRef.current?.href}
          className={twMerge(
            cn('overflow-hidden'),
            blogCardImageWrapperStyles({blogType}),
          )}
          target={target}
        >
          <img
            loading="lazy"
            src={article.imageUrl}
            alt={article.imageAltText || article.title}
            className={blogCardImageStyles({type})}
          />
        </ImageWrapper>
      )}
      <div className="blogPost pt-4">
        {articleTag && (
          <div className={eyebrowStyles}>
            {article?.disableTopicLink ? (
              <span className={articleTopicStyles}>{articleTag}</span>
            ) : (
              <a href={getTagLink()} className={articleTopicStyles}>
                {articleTag}
              </a>
            )}
          </div>
        )}
        <div className={twMerge(blogCardTitleStyles({type, blogType, mode}))}>
          <a
            className={twMerge(blogCardTitleStyles({type, blogType, mode}))}
            ref={linkRef}
            href={article.path ?? localizePath(enBlogPath + article.handle)}
            rel={target === '_blank' ? 'noopener noreferrer' : ''}
            target={target}
          >
            {article.title}
          </a>
        </div>
        {isHero ? (
          <>
            <div
              className={twMerge(
                cn(
                  'text-base desktop:text-body-base pb-4 leading-6 desktop:leading-[1.8rem] desktop:tracking-[-.02em]',
                  blogCardDescriptionStyles({blogType, mode}),
                ),
              )}
            >
              {article.excerpt || article.seo?.description}
            </div>
            <div className="flex self-center flex-col">
              <BlogAuthorName
                blogPageType={blogType}
                ns={translationNamespace || `pages${enBlogPath}content`}
                enBlogPath={enBlogPath}
                authorData={authorData}
                className={twMerge(
                  blogCardAuthorStyles({
                    isMobileHidden: false,
                    blogType,
                    mode,
                  }),
                )}
              />
              {article.modifiedAt && (
                <Typography
                  size="body-sm"
                  className={twMerge(
                    cn(
                      'font-shopifysans font-normal text-shade-60',
                      blogCardDateStyles({blogType, mode}),
                    ),
                  )}
                >
                  {article.modifiedAt}
                </Typography>
              )}
            </div>
          </>
        ) : (
          <Typography
            size="body-sm"
            className={twMerge(
              cn(
                'font-shopifysans font-normal text-shade-60',
                blogCardDateStyles({blogType, mode}),
              ),
            )}
          >
            {article.modifiedAt}
          </Typography>
        )}
      </div>
    </article>
  );
}
